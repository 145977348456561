import React, { Component } from "react";
import _ from "lodash";
const config = require("../../../data/SiteConfig")

class PostTags extends Component {
  render() {
    const { tags } = this.props;
    return (        
      <div className="post-tag-container mt-4 pt-4 capitalize">
        {tags &&
          tags.map(tag => (
            <span className="inline-flex items-center px-3 py-0.5 rounded text-sm font-medium leading-5 bg-indigo-100 text-indigo-800 mr-2 mb-2">
            <a
              key={tag}
              href={`/${config.blogTagsDirectory}/${_.kebabCase(tag)}`}
            >
              {tag}
            </a>
            </span>               
          ))}
      </div>
    );
  }
}

export default PostTags;
