import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import PostTags from "../components/PostTags/PostTags";
import SEO from "../components/SEO/SEO";
import Footer from "../components/Footer/Footer";
import config from "../../data/SiteConfig";


export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }

    return (
      <Layout>       
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>   
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />             
        <div>

            <div className="pt-12 bg-white overflow-hidden">
            <div className="relative mx-auto pl-4 pr-4">
                <div className="relative">
                <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    {post.title}
                </h2>
                <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
                    {post.subtitle}
                </p>
                <div className="mt-4 flex-shrink-0">
                    <img 
                        src={post.cover.childImageSharp.resize.src} 
                        width="100%" 
                        data-src={post.cover.childImageSharp.resize.src} 
                        className="lazyload w-full object-cover rounded sm:h-24 md:h-32 lg:h-80 xl:h-88" 
                        alt={post.title}/>
                </div>
                                        
                </div>

                <div className="relative mt-12">
                <div className="relative">
                    <div dangerouslySetInnerHTML={{ __html: postNode.html }} className="mt-3 text-lg leading-7 text-gray-900" />
                </div>
                </div>

                <PostTags tags={post.tags} />

            </div>
            </div>
            
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        subtitle
        date
        cover {
            childImageSharp {
                resize(width: 1024) {
                    width
                    height
                    src
                }
            }
        }
        category
        tags
      }
      fields {
        slug
      }
    }
  }
`;
